<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main">
        <el-row>
          <el-form :model="form" :rules="rules" ref="form" :inline="true" label-position="left" label-width="108px">
            <!-- 电子规则子类： 类别选择 -->
            <el-col :span="12" class="bottom-line">
              <el-form-item style="width: 100%;" label="企业名称:">
                <span class="content">{{companyName}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="bottom-line">
              <el-form-item label="企业信用代码:">
                <span class="content">{{companyCode}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="24" class="bottom-line">
              <el-form-item label="经营地址:">
                <span class="content">{{companyAddress}}</span>
              </el-form-item>
            </el-col>

          </el-form>
        </el-row>
      </div>
      <div class="content-main" style="margin-top: 24px">
        <ayl-table :table="table">
          <div slot="ctrl-button">
          </div>
        </ayl-table>
      </div>
      <el-dialog
      :title="dialogTitle"
      :visible.sync="centerDialogVisible"
      width="900px"
      top="30vh"
      center>
      <div class="content-box">
        <div class="content-main" style="-webkit-box-shadow: none;box-shadow: none;border-radius: 4px;border: none;">
          <el-form :model="form" :rules="rules" ref="form">
            <el-form-item label="账户类型" :label-width="formLabelWidth" prop="accType">
                  <el-select v-model="form.accType" placeholder="请选择" value-key="id" @change='changeSelect'>
                  <el-option v-for="(item, idx) in accTypeArr"
                              :key="idx"
                              :label="item"
                              :value="item"/>
                  </el-select>
              </el-form-item>
              <el-form-item label="账户名称" :label-width="formLabelWidth" prop="accName">
                  <el-input v-model="form.accName" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="账户账号" :label-width="formLabelWidth" prop="accNo">
                  <el-input v-model="form.accNo" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="开户行" :label-width="formLabelWidth" prop="accBank">
                  <el-input v-model="form.accBank" autocomplete="off"></el-input>
              </el-form-item>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
          <el-button @click="centerDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submit" :loading="submitLoading">确 定</el-button>
      </span>
      </el-dialog>
      <el-button type="success" class="btn-success" style="margin: 24px" @click="add">新增账户</el-button>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      const vm = this
      return {
        nav: [
          {name: '系统管理', path: '/system-manage/permissions-allot'},
          {name: '企业管理'}
        ],
        companyInfo: {},
        companyName: null,
        companyCode: null,
        companyAddress: null,
        submitLoading: false,
        centerDialogVisible: false,
        formLabelWidth: '150px',
        dialogTitle: '编辑',
        accId: null,
        accTypeArr: ['企业账户', '普通账户'],
        table: {
          api: vm.$api.settlementList,
          query: {
            queryContent: null,
          },
          // 是否显示分页
          hidePagination: true,
          // 表格查询的条件组件
          searchData: [],
          columns: [
            {
              title: '账户类型',
              key: 'accType',
            }, {
              title: '账户名称',
              key: 'accName',
            },{
              title: '开户行',
              key: 'accBank',
            },{
              title: '账户账号',
              key: 'accNo',
            }, {
              title: '操作',
              width: '155px',
              render (h, ctx) {
                return h('span', [
                  h('span', {
                    class: {
                      'table-view': true,
                    },
                    on: {
                      click: vm.editCompany.bind(this, ctx.row)
                    }
                  }, '编辑'),
                  h('span', {
                    class: {
                      'table-delete': true
                    },
                    on: {
                      click: vm.deleteCompany.bind(this, ctx.row)
                    }
                  }, '删除')
                ])
              }
            }]
        },
        form: {
          accType: null,
          accName: null,
          accNo: null,
          accBank: null,
          accId: null,
        },
        rules: {
          accType: {required: true, message: '请选择账户类型', trigger: 'change'},
          accName: [{max: 16, required: true, message: '请输入账户名称', trigger: 'blur'},{validator: this.$validator.isContainBlank}],
          accNo: [{max: 32, required: true, message: '请输入账户账号', trigger: 'blur'},{validator: this.$validator.isContainBlank}],
          accBank: [{max: 32, required: true, message: '请输入开户行', trigger: 'blur'},{validator: this.$validator.isContainBlank}],
        },
      }
    },
    methods: {
      changeSelect(){

      },
      /**
       * 新增账户、编辑
       */
      async onSuccess(){
        if(this.dialogTitle == '新增账户'){
          await this.$api.addCompanyInfo({steelAccountInfoVO: this.form})
          this.$notify({
            title: '成功',
            message: '新增成功',
            type: 'success'
          });
          this.centerDialogVisible = false
          this.form.accType = null,
          this.form.accName = null,
          this.form.accNo = null,
          this.form.accBank = null,
          await this.$search(this.table)
        }else{
          //console.log('编辑...')
          await this.$api.updateCompanyInfo({steelAccountInfoVO: this.form})
          this.$notify({
            title: '成功',
            message: '编辑成功',
            type: 'success'
          });
          this.centerDialogVisible = false
          this.form.accType = null,
          this.form.accName = null,
          this.form.accNo = null,
          this.form.accBank = null,
          this.form.accId = null,
          await this.$search(this.table)
        }
      },
      /**
       * 提交表单
       */
      submit() {
        this.$refs.form.validate(async valid => {
          if (!valid) return
          this.submitLoading = true
          try {
            this.onSuccess()
          } catch (e){}
          this.submitLoading = false
        })
      },
      /**
       * 新增账号
       */
      async add(){
        this.form.accType = null,
        this.form.accName = null,
        this.form.accNo = null,
        this.form.accBank = null,
        this.dialogTitle = '新增账户'
        this.centerDialogVisible = true
        var isCompany = await this.$api.AccountType({accType: "企业账户"})
        //console.log(isCompany)
        if(isCompany.toString().indexOf('true') != -1){
          this.accTypeArr = ['普通账户']
        }else{
          this.accTypeArr = ['企业账户','普通账户']
        }
      },
      /**
       * 编辑企业
       */
      async editCompany (val) {
        //console.log(val)
        this.form = await this.$api.detailsCompanyInfo({accId: val.accId})
        this.dialogTitle = '编辑'
        this.centerDialogVisible = true
        var isCompany = await this.$api.AccountType({accType: "企业账户"})
        //console.log(isCompany)
        if(isCompany.toString().indexOf('true') != -1 && val.accType != '企业账户'){
          this.accTypeArr = ['普通账户']
        }else{
          this.accTypeArr = ['企业账户','普通账户']
        }
      },
      /**
       * 删除企业
       */
      async deleteCompany(val){
        await this.$confirm(`您确认删除吗?`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
          })
          await this.$api.deleteCompanyInfo({
              accId: val.accId
          })
          this.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success'
          })
          await this.$search(this.table)
      },
    },
    async mounted () {
      await this.$search(this.table)
      this.companyInfo = await this.$api.getCompanyInfo({})
      this.companyName = this.companyInfo.company
      this.companyCode = this.companyInfo.unifiedCreditCode
      this.companyAddress = this.companyInfo.address
    }
  }
</script>

<style lang='sass' scoped>
.content-box /deep/ .el-form-item
    width: 41%
    display: inline-block

/deep/ .el-dialog__header
    border-bottom: 1px solid $grayShallow3

/deep/.el-date-editor.el-input
    width: 160px
</style>
